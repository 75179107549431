import Axios from './http';
const prefix = '/consumer/v1';

import _ from 'lodash';
export default class Analysis {
  static async subject(params) {
    return Axios(prefix + '/analysis/subject', {
      method: 'get',
      responseType: 'json',
      params,
    });
    // .then((res) => {
    //   if (res.code === 1) {
    //     console.log('/analysis/subject 修改 id => key');
    //     res.data.rows = res.data.rows.map((x) => {
    //       return { ...x };
    //     });
    //   }

    //   return res;
    // });
  }
  static async intro(params) {
    return Axios(prefix + '/analysis/intro', {
      method: 'get',
      responseType: 'json',
    }).then((res) => {
      if (res.code === 1) {
        console.log('//analysis/intro 修改 數字type');
        res.data.rows = res.data.rows.map((x) => {
          return {
            ...x,
            completeCnt: Number(x.completeCnt),
            correctRate: Number(x.correctRate),
          };
        });
      }

      return res;
    });
  }

  static async overallBar(params) {
    return Axios(prefix + '/analysis/overall/' + params.type, {
      method: 'get',
      responseType: 'json',
      params: {
        period: params.period,
        sbjId: params.sbjId,
      },
    });
  }

  static async overall(params) {
    return Axios(prefix + '/analysis/overall', {
      method: 'get',
      responseType: 'json',
      params,
    });
  }
}
